import Swiper, { Autoplay } from "swiper"

Swiper.use([Autoplay])

const blocks = document.querySelectorAll(".usps-slider")

blocks.forEach((block) => {
    new Swiper(block, {
        direction: "vertical",
        slidesPerView: 3,
        centeredSlides: true,        
        loop: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
    })
})
