import SmoothScroll from "smooth-scroll"
import { animate } from 'popmotion'

import "./components/sliders/banner-slider"
import "./components/sliders/review-slider"
import "./components/sliders/image-slider"
import "./components/sliders/usps-slider"
import "./components/extension-blocks"
import "./layout/header"
import "./components/location-map"
import "./components/review-slider-stars"
import "./components/review-total-stars"
import "./components/occasion-detail"
import "./components/marked-map"
import "./components/highlighted-occasions"

new SmoothScroll('a[href^="#"]', {
    speed: 300,
    offset: function () {
        return 100
    },
    easing: "Linear",
    ignore: 'a[href^="#openChat"]',
    topOnEmptyHash: false,
})


document.addEventListener("DOMContentLoaded", function () {
  const btn = document.querySelector(".action-popup button")
  if (!btn) {
    return;
  }
  
  btn.addEventListener("click", function () {
    localStorage.setItem("popup-closed-2023", true)
    document.querySelector(".action-popup").style.display = "none";
  })


  if (!localStorage.getItem("popup-closed-2023")) {
    animate({
      from: -200,
      to: 15,
      autoplay: true,
      onUpdate: (v) => {
        document.querySelector(".action-popup").style.right = `${v}px`
      },
      onPlay: () => {
        document.querySelector(".action-popup").style.display = "flex";
      }
    })
  }
});